import React from 'react';
import { Loader2 } from "lucide-react";

const LoadingOverlayCommon = () => {
  return (
    <div className="fixed inset-0 bg-black/60 backdrop-blur-md flex items-center justify-center z-[99999]">
      <div className="bg-white/90 p-8 rounded-2xl shadow-2xl flex flex-col items-center relative">
        {/* Spinning circles background */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-24 h-24 border-4 border-[#FF6100]/20 rounded-full animate-ping" />
          <div className="absolute w-32 h-32 border-4 border-[#FF6100]/10 rounded-full animate-ping animation-delay-300" />
        </div>
        
        {/* Loader and text */}
        <div className="relative z-10 flex flex-col items-center">
          <Loader2 className="animate-spin text-[#FF6100]" size={32} />
          <p className="mt-4 text-gray-700 font-medium animate-pulse">
            Fetching information, please wait...
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoadingOverlayCommon; 