import React, { createContext, useContext, useState } from 'react';

const FormContext = createContext();

export const FormProvider = ({ children }) => {
  // Get tomorrow's date for pickup
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  
  // Get return date (2 days after pickup date)
  const returnDateDefault = new Date(tomorrow);
  returnDateDefault.setDate(returnDateDefault.getDate() + 2);

  // Format dates as strings
  const formatDate = (date) => date.toLocaleDateString('en-US');

  const [formData, setFormData] = useState({
    selectedLocation: "",
    selectedLocationId: "",
    pickupDate: formatDate(tomorrow),
    returnDate: formatDate(returnDateDefault),
    pickupTime: "11:00 AM",
    returnTime: "11:00 AM",
    selectedVehicle: null,
    selectedReturnLocation: '',
    selectedReturnLocationId: '',
    selectedReturnLocationSource: '',
  });

  const updateFormData = (newData) => {
    setFormData(prev => ({ ...prev, ...newData }));
  };

  return (
    <FormContext.Provider value={{ formData, updateFormData }}>
      {children}
    </FormContext.Provider>
  );
};

export const useFormData = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useFormData must be used within a FormProvider');
  }
  return context;
};
