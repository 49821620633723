import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const SESSION_TIMEOUT = 30 * 60 * 1000; // 30 minutes in milliseconds

  const shouldTrackSession = () => {
    const path = location.pathname;
    return path.includes('/vehicle') || path.includes('/checkout');
  };

  const resetTimer = () => {
    if (shouldTrackSession()) {
      setLastActivity(Date.now());
    }
  };

  const handleTimeout = () => {
    setShowTimeoutModal(true);
  };

  const handleRedirectHome = () => {
    setShowTimeoutModal(false);
    setLastActivity(Date.now());
    navigate('/');
    window.location.reload();
  };

  useEffect(() => {
    let interval;
    
    if (shouldTrackSession()) {
      const handleBeforeUnload = (e) => {
        sessionStorage.setItem('wasOnRestrictedPage', 'true');
        const message = 'Are you sure you want to refresh? All your search details will be lost.';
        e.returnValue = message;
        return message;
      };
      
      const timeoutId = setTimeout(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
      }, 100);
      
      interval = setInterval(() => {
        if (Date.now() - lastActivity >= SESSION_TIMEOUT) {
          handleTimeout();
        }
      }, 1000);

      return () => {
        if (interval) {
          clearInterval(interval);
        }
        clearTimeout(timeoutId);
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [lastActivity, location]);

  // Check on page load if we need to redirect
  useEffect(() => {
    const wasOnRestrictedPage = sessionStorage.getItem('wasOnRestrictedPage');
    if (wasOnRestrictedPage === 'true' && shouldTrackSession()) {
      sessionStorage.removeItem('wasOnRestrictedPage');
      window.location.href = '/';
    }
  }, [location]);

  return (
    <SessionContext.Provider value={{ 
      resetTimer, 
      showTimeoutModal, 
      handleRedirectHome
    }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => useContext(SessionContext); 